<template>
  <div>
    <a-button-group>
      <a-button icon="edit" size="small" @click="updateModalVisible = true">编辑</a-button>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
    </a-button-group>

    <UpdateFormModal v-model="updateModalVisible" :item="item" @update="update" @cancel="updateModalVisible = false" />
  </div>
</template>

<script>
import { inspectionStandardDestroy } from "@/api/inspection";

export default {
  components: {
    UpdateFormModal: () => import("./UpdateFormModal"),
  },
  props: ["item"],
  data() {
    return {
      updateModalVisible: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    destroy() {
      inspectionStandardDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
  },
};
</script>

<style scoped></style>
